$color-btn-text: $darkgrey;
$color-btn-default: $color-main;
$color-btn-hot: $red;
$color-btn-cool: $blue;


.btn{
  display: inline-block;
  padding: 6px 12px;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid $color-main;
  border-radius: 4px;
  font-family: $font-highlight;
  user-select: none;
  color: $color-btn-text;
}

.btn-default{
  @extend .btn;
  background: $btn-color;
  &:focus{
    @extend .btn-default;
  }
  &:hover, &:active, &:active:focus{
    background-color: darken($btn-color, 10%);
    color: $grey;
  }
}

a{
  text-decoration: none;
  color: $color-main;
  &:hover{
    text-decoration: none;
    color: $color-main;
  }
  &:focus{
    text-decoration: none;
    color: $color-main;
  }
}