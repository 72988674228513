.padding-5{
  padding: 5px;
}

.padding-10{
  padding: 10px;
}

.tn{
  max-width: 100px;
}