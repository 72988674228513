.ap-scrubberContainer{
  display: block;
  width: 90%;
  height: $scrubber-height;
  margin: 0 auto;

  background-color: $player-color-main;
  .ap-scrubberMouseover{
    height:$scrubber-height;
    position: relative;
    bottom: 25px;
    background-color: transparentize($player-color-interface, .7);
    display: block;
  }
  .ap-scrubberBar{
    display: inline;
    width: 100%;
    .ap-scrubber{
      display: inline-block;
      text-align: center;
      height:$scrubber-height;
      color: $player-color-interface;
      background-color: $player-color-interface;
    }
  }
}