#intro{
  padding-left: 25px;
  padding-top: 20px;
  img{
    @include boxShadow;
  }
}
@media screen and (max-width: 767px) {
  #intro{
    padding: 0;
    img{
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      margin-top: 40px;
      @include boxShadow;
    }
    p{
      text-align: center;
    }
  }
}