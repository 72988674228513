html {
 font-size: 62.5%;
}

body {
 margin: 0;
 padding: 0;
 font-size: 1.8rem;
 font-family: $font-main;
 color: $color-main;
 background-color: $color-backgrounds;
}

h1,h2,h3,h4,h5,h6{
 margin: 0;
 font-family: $font-highlight;
 color: $color-headlines-other;
 text-align: center;
}

section{
 .container{
  border-bottom: 5px solid rgba($lightblue, .4);
  margin: 15px !important;
 }
 &:last-of-type, &:first-of-type {
  .container{
   border-bottom: none;
  }
 }
}

.feature{
 border-bottom: 1px dashed rgba($lightblue, .8);
 padding: 30px 0;
 &:last-of-type {
  border-bottom: none;
 }
}