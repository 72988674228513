.panel{
  background-color: hsla(0, 0%, 100%, .5);
  color: $black;
  border:1px solid $color-main;
  @include boxShadow($darkgrey, 3px, 3px);
  .panel-heading{
    background-color: $btn-color;
    padding:0;
    a{
      display: block;
      padding:10px 15px;
    }
    &:hover{
      background-color: darken($btn-color, 10%);
      color: $color-main;
    }
  }
  button{
    background: transparent;
    border: none;
  }
}