@mixin backImage($image,
  $height: 100vh,
  $bgPos: center center)
{
  background: linear-gradient( to bottom,
  rgba(0,0,0,0),
  rgba(0,0,0,.6)),
  url($image);
  background-repeat: no-repeat;
  background-position: $bgPos;
  background-size: cover;
  height: $height;
}

@mixin backColor($color1,
  $color2: hsla(0,100%,0,.6),
  $height: 100vh)
{
  background: linear-gradient( to bottom,
    $color1,
    $color2);
  height: $height;
}

@mixin clearfix {
  &:before,
  &:after {
    content: '';
    display: table;
  }
  &:after {
    clear: both;
  }
}
@mixin rounded ($radius: $border-radius){
  -webkit-border-radius: $radius;
  border-radius: $radius;
  background-clip: padding-box;
}

@mixin btn ($border: none, $fontsize: 1.1em, $color: $player-color-interface){
  display: inline-block;
  background: transparent;
  border: $border;
  min-width: 30px;

  color: $color;
  font-size: $fontsize;
}

@mixin interactiveOpacity ($opacity: .6){
  & + a img {
    opacity: $opacity;
  }
}

@mixin boxShadow ($color: $darkergrey, $v: 5px, $h: 5px) {
  box-shadow: $v $h 5px $color;
}