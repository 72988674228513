$color-item-border: $lightgray;
$color-media-head:  $offwhite;

.media{
  margin: 0;
  padding: 0;
  list-style: none;

  .item{
    padding-bottom: 10px;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    margin: 15px 0 10px;
    .ad{
      padding:30px 5px 5px;
      a{
        &:hover{
            @include interactiveOpacity();
        }
      }
      img{
        padding: 0;
        @include rounded(20%);
      }
    }
    .amazon{
      padding:5%;
    }

    &:first-of-type {
      border-top: none;
    }
    @include clearfix;
    &.video{
      border: none;
    }
  }

  .head {
    margin: 0;
    position: relative;
    padding: 0 0 10px;
    color: $color-media-head;
    font-size: 2.2rem;
    #typography & {
      color: $red;
    }
  }
  p{
    margin: 0;
    font-size: 1.5rem;
  }
  a {
    text-decoration: none;
    color: $darkgrey;
  }
  audio{
    margin:0 0 15px;
    padding:0;
  }
}

.img-responsive{
  width:100%;
  margin-left:auto;
  margin-right:auto;
}

.embed-responsive{
  @include boxShadow($black);
}

.tn{
  padding-top: 60px;
  min-width: 30px;
}

#steps-img{
  max-width: 845px;
}
#contact img{
  max-width: 70px;
}

.feature {
  padding: 60px 0;
}