/*! Load Fonts */
@import url('https://fonts.googleapis.com/css?family=Amatic+SC|Archivo+Narrow|Merriweather|Proza+Libre|Quicksand|Roboto+Slab|Rubik');

$black:         #000000;
$offwhite:      #fdfff8;
$lightgray      : #819090;
$grey:          #bbb;
$darkgrey:      #333;
$darkergrey:    darken($darkgrey, 30%);
$darkblue:      #0e0a26;
$yellow:        #fff426;
$lighterblue:   #3ddbff;
$lightblue:     hsla(201, 100%, 60%, .9);
$blue:          #513ce5;
$green:         #08ff49;
$darkgreen:     darken($green, 25%);
$orange:        #ffac26;
$darkorange:    #b36200;
$red:           #fa264b;
$magenta:       #873b3a;
$lightmagenta:  #c08488;
$purple:        #8e30e3;


$color-main:              $offwhite;
$color-backgrounds:       $lightblue;
$color-headlines-main:    $lightblue;
$color-headlines-other:   $lighterblue;

$player-color-main:              $lightgray;
$player-color-backgrounds:       $darkgrey;
$player-color-interface:         $lighterblue;
$player-color-border:            $lighterblue;
$scrubber-height:                 10px;


$border-radius: 5px;

$btn-color:         $lightblue;

$font-main: 'Archivo+Narrow', Helvetica, sans-serif;
$font-highlight: 'Merriweather', Helvetica, sans-serif;

$xs-breakpoint: 767px;