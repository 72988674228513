.ap-controls{
  margin: $border-radius;
  text-align: center;
  width: 100%;
  .ap-navigation {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    display: inline;
    .ap-togglePlay, .ap-back10, .ap-skip10 {
      @include btn();
      &:hover{
        color: lighten($player-color-interface, 20%);
      }
    }
  }
}